import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import CategoryPage from './pages/CategoryPage';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import OrderView from './pages/OrderView';
import MarketPlace from './pages/MarketPlace';
import AdminProductsPage from './pages/AdminProductsPage';
import Adminorder from './pages/Adminorder';
import CustomerForm from './pages/CustomerForm';
import Ownerlogin from './pages/Ownerlogin';
import Customerlogin from './pages/Customerlogin';
import Customersignup from './pages/Customersignup';
import CustomerOrdered from './pages/CustomerOrdered';
import AccountSettings from './pages/AccountSettings';
import CheckoutOrders from './pages/CheckoutOrders';
import Messages from './pages/Messages';
import ExchangeRates from './pages/ExchangeRates';
import CustomersData from './pages/CustomersData';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'ordersview', element: <OrderView /> },
        { path: 'marketplace', element: <MarketPlace /> },
        { path: 'user', element: <UserPage /> },
        { path: 'category', element: <CategoryPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'adminProducts', element: <AdminProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'adminorders', element: <Adminorder /> },
        { path: 'exchangerates', element: <ExchangeRates /> },
        { path: 'customerdata', element: <CustomersData /> },
        { path: 'CustomerForm', element: <CustomerForm /> },
        { path: 'CustomerOrdered', element: <CustomerOrdered /> },
        { path: 'accountSettings', element: <AccountSettings /> },
        { path: 'checkoutOrders', element: <CheckoutOrders /> },
        { path: 'messages', element: <Messages /> },
      ],
    },
    {
      path: 'Customerlogin',
      element: <Customerlogin />,
    },
    {
      path: 'Customersignup',
      element: <Customersignup />,
    },
    {
      path: 'ownerlogin',
      element: <Ownerlogin />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
