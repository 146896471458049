import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_SERVICE } from 'src/config';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Dialog, DialogContentText, DialogTitle, DialogActions, DialogContent, Divider } from '@mui/material';
import { isExpired, decodeToken } from 'react-jwt';
import { Typography } from '@mui/material';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';

export default function CustomerOrdered() {
  const [orders, setorders] = useState([]);
  const [show, setshow] = useState(false);
  const Navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    const token1 = localStorage.getItem('token');
    var token = JSON.parse(token1);
    console.log(token);
    if (!token) {
      console.log('no token');
      Navigate('/Customerlogin');
    } else if (token) {
      const owner = decodeToken(token[0].Owner);
      const customer = decodeToken(token[0].Customer);
      if (!customer) {
        console.log('invalid token');
        localStorage.removeItem('token');
        Navigate('/Ownerlogin');
      } else {
        console.log('valid user');
      }
    }
  }, []);
  // const orders;
  const [event, setEvent] = useState({});
  const handleClickToOpen = (e) => {
    setOpen(true);
    setEvent(e);
  };

  const handleToClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const token1 = localStorage.getItem('token');
    var token = JSON.parse(token1);
    axios
      .get(`${API_SERVICE}/getCustomerOrdered/${token[2]}`)
      .then((response) => {
        console.log(response);
        setorders(response.data);
        console.log(orders);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Billing Address</TableCell>
              <TableCell align="center">Product</TableCell>
              <TableCell align="center">Color</TableCell>
              <TableCell align="center">Qty.</TableCell>
              <TableCell align="center">Total Cost</TableCell>
              <TableCell> View More </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((item) => {
              return (
                <TableRow key={item._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {item.fname} {item.lname}
                  </TableCell>
                  <TableCell align="center">{item.CompanyName}</TableCell>
                  <TableCell align="center">{item.BillingAddress}</TableCell>

                  <TableCell align="center">{item.productName}</TableCell>
                  <TableCell align="center">{item.color}</TableCell>
                  <TableCell align="center">{item.qty}</TableCell>
                  <TableCell align="center">${(item.qty * item.cost).toFixed(2)}</TableCell>
                  <TableCell align="center">
                    <Button variant="outlined" sx={{ float: 'left' }} onClick={() => handleClickToOpen(item)}>
                      VIEW MORE
                    </Button>
                    <Dialog fullWidth maxWidth="md" open={open} onClose={handleToClose}>
                      <DialogContent>
                        <center>
                          <img style={{ widht: '250px', height: '250px' }} src={event.img} />
                        </center>
                        <Divider sx={{ my: 3 }} />
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          CompanyName : <Typography>{event.CompanyName}</Typography>
                        </Stack>
                        <Divider sx={{ my: 3 }} />
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          category : <Typography>{event.category}</Typography>
                        </Stack>

                        <Divider sx={{ my: 3 }} />

                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          description : <Typography>{event.description}</Typography>
                        </Stack>
                        <Divider sx={{ my: 3 }} />
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          length : <Typography>{event.length}</Typography>
                        </Stack>
                        <Divider sx={{ my: 3 }} />
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          material : <Typography>{event.material}</Typography>
                        </Stack>
                        <Divider sx={{ my: 3 }} />
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          stocks : <Typography>{event.stocks}</Typography>
                        </Stack>

                        <Divider sx={{ my: 3 }} />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleToClose} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
