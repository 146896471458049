import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
  Container,
  Stack,
  Typography,
  Button,
  TextField,
  CircularProgress,
  ListItemText,
  Grid,
  Pagination,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Paper,
  TableHead,
  TableBody,
  Skeleton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
// import { ProductSort, AdminProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import {
  AdminProductList,
  ProductSort,
  ProductCartWidget,
  ProductFilterSidebar,
} from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/products';
import * as React from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import FileInput from './firebase/FileInput';

// importing model
// import adminProduct from "./../../"

import { API_SERVICE } from '../config';
// ----------------------------------------------------------------------
import { isExpired, decodeToken } from 'react-jwt';

export default function AdminProductsPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(false);
  const [loading, setloading] = useState(true);
  const [productsData, setproductsData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [category, setCategory] = useState('');
  const [productId, setproductId] = useState('');
  const [categories, setCategories] = useState([]);
  const [searchText, setSearchText] = useState('');

  /// Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const [totalPages, setTotalPages] = useState(0);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const [open3, setOpen3] = useState(false);
  const handleClickOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
    setCSVSelectedFile(null);
    setCSVData([]);
  };

  const handleInputState = (name, value) => {
    const values = Object.values(value);
    setState((prev) => ({ ...prev, [name]: values }));
  };

  const admin = {
    name: 'dummy',
    id: 'dnjcndskjcnjnckncjsnajkcnjsansa',
  };

  const [state, setState] = useState({
    name: '',
    product_code: '',
    weight: '',
    length: '',
    diameter: '',
    material: '',
    color: '',
    price: 0,
    stocks: 0,
    description: '',
    available: false,
  });

  useEffect(() => {
    if (state?.urls?.length === state?.img?.length) {
      setSubmitBtn(false);
    }
  }, [state]);

  const addProduct = async () => {
    setloading(true);
    const dataForm = {
      name: state.name,
      product_code: state.product_code,
      weight: state.weight,
      length: state.length,
      diameter: state.diameter,
      material: state.material,
      color: state.color,
      price: state.price,
      stocks: state.stocks,
      description: state.description,
      available: state.available,
      adminId: admin?.id,
      category: category,
      img: state.urls,
    };
    try {
      const response = await fetch(`${API_SERVICE}/addAdminProduct`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataForm),
      });
      if (response.status === 200) {
        handleClose();
        getAllProducts(currentPage);
        setState({
          name: '',
          category: '',
          product_code: '',
          weight: '',
          length: '',
          diameter: '',
          material: '',
          color: '',
          price: 0,
          stocks: 0,
          description: '',
          available: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    getAllProducts(currentPage);
  }, []);

  const getAllProducts = async (page) => {
    try {
      const response = await fetch(`${API_SERVICE}/getallAdminProducts/${admin?.id}`);
      const data = await response.json();

      // setproductsData(data);
      setAllData(data);
      // const temp = [data[1], data[2], data[3], data[4], data[5], data[6], data[7], data[8], data[9], data[10]];

      // setproductsData(temp);
      // totalProductsLength = data.length;
      // console.log("Data:",data);
      const startIdx = (page - 1) * PRODUCTS_PER_PAGE;
      const endIdx = startIdx + PRODUCTS_PER_PAGE;
      const productsPage = data.slice(startIdx, endIdx);
      setproductsData(productsPage);

      setInterval(() => {
        setloading(false);
      }, 5000);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  // searching

  const searchProducts = async () => {
    try {
      const response = await fetch(`${API_SERVICE}/searchAdminProducts/${productId}?search=${searchText}`);
      const data = await response.json();

      setproductsData(data);

      setloading(false);
    } catch (err) {
      // setproductsData();
      console.log(err);
    }
  };

  // Pagination

  // const handlePageChange = (event, value) => {
  //   setCurrentPage(value);
  // };

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     setloading(true);
  //     const data = await getAllProducts(currentPage, pageSize);
  //     setproductsData(data.products);
  //     setTotalPages(data.totalPages);
  //     setloading(false);
  //   };

  //   fetchProducts();
  // }, [currentPage, pageSize]);
  const PRODUCTS_PER_PAGE = 16;
  // const totalPages = Math.ceil(totalProductsLength / PRODUCTS_PER_PAGE);
  // console.log(totalProductsLength, totalPages, PRODUCTS_PER_PAGE);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setloading(true);
    getAllProducts(page);
  };
  useEffect(() => {
    setloading(true);
    getAllProducts(currentPage);
  }, [currentPage]);

  // reset seacrh
  const resetSeacrh = () => {
    setSearchText('');
    searchProducts();
  };

  useEffect(() => {
    const token1 = localStorage.getItem('token');
    var token = JSON.parse(token1);
    if (token) {
      const owner = decodeToken(token[0].Owner);
      const customer = decodeToken(token[0].Customer);
      if (!owner) {
        console.log('invalid token');
        localStorage.removeItem('token');
        Navigate('/Ownerlogin');
      } else {
        console.log('valid user');
        // populateQuote()
      }
    }
  }, []);

  const getAllCategories = async () => {
    try {
      const response = await fetch(`${API_SERVICE}/getallcategories/fenfjsnfjewnjnelleslnlenfefneflk`);
      const data = await response.json();
      setCategories(data.filter(checkStatus));
      function checkStatus(c) {
        return c.status == true;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteProduct = async (id) => {
    setloading(true);
    try {
      const response = await fetch(`${API_SERVICE}/deleteAdminProduct/${id}/${admin?.id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      getAllProducts(currentPage);
    } catch (err) {
      console.log(err);
    }
  };

  const updateProduct = async (product) => {
    setState({
      name: product.name,
      category: product.category,
      product_code: product.product_code,
      weight: product.weight,
      length: product.length,
      diameter: product.diameter,
      material: product.material,
      color: product.color,
      price: product.price,
      stocks: product.stocks,
      description: product.description,
      available: product.available,
    });
    setproductId(product?._id);
    handleClickOpen2();
  };

  const updateProduct2 = async () => {
    setloading(true);
    try {
      const response = await fetch(`${API_SERVICE}/updateAdminProduct/${productId}/${admin?.id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...state }),
      });
      getAllProducts(currentPage);
      handleClose2();
      setState({
        name: '',
        category: '',
        product_code: '',
        weight: '',
        length: '',
        diameter: '',
        material: '',
        color: '',
        price: 0,
        stocks: 0,
        description: '',
        available: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const copyProduct = async (product) => {
    setState({
      name: product.name,
      category: product.category,
      product_code: product.product_code,
      weight: product.weight,
      length: product.length,
      diameter: product.diameter,
      material: product.material,
      color: product.color,
      price: product.price,
      stocks: product.stocks,
      description: product.description,
      available: product.available,
    });
    setproductId(product?._id);
    handleClickOpen();
  };

  /* CSV Data Logic */
  const [CSVSelectedFile, setCSVSelectedFile] = useState(null);
  const [CSVData, setCSVData] = useState([]);
  // console.log(CSVSelectedFile);

  const handleCSVFileUpload = (event) => {
    const file = event.target.files[0];
    setCSVSelectedFile(file);
  };

  const handleCSVSubmit = () => {
    if (CSVSelectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target.result;
        // console.log('CSV DATA: ', csvData);
        const parsedData = parseCSVData(csvData);
        // console.log('Parsed DATA: ', parsedData);
        setCSVData(parsedData);
        processCSVData(parsedData)
          .then(() => {
            handleClose3();
            getAllProducts(currentPage);
            setState({
              name: '',
              category: '',
              product_code: '',
              weight: '',
              length: '',
              diameter: '',
              material: '',
              color: '',
              price: 0,
              stocks: 0,
              description: '',
              available: false,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      };
      reader.readAsText(CSVSelectedFile);
    }
  };

  const parseCSVData = (csvData) => {
    const lines = csvData.split('\n');
    const headers = lines[0].split(',');
    const data = [];

    for (let i = 1; i < lines.length; i++) {
      const fields = lines[i].split(',');
      let rowData = {};

      for (let j = 0; j < headers.length; j++) {
        const fieldValue = fields[j].trim(); // Trim leading/trailing whitespace
        // console.log('Headers: ', j, headers[j]);
        if (fieldValue === '') {
          rowData[headers[j]] = null; // Set empty field as null
        } else {
          rowData[headers[j]] = fieldValue;
        }
      }

      // Process the "Available Status" field
      // let availableStatus = rowData['"Available Status" "'].slice(0, -1);

      // if (availableStatus && availableStatus.toLowerCase() === 'true') {
      //   availableStatus = true;
      // } else {
      //   availableStatus = false;
      // }
      // console.log(rowData);
      rowData[headers[11]] = rowData[headers[11]] === 'True"' ? true : false;

      rowData['"Name'] = rowData['"Name'].slice(1);

      // Remove the first 2 and last 2 characters from Description
      rowData['Description'] = rowData['Description'].slice(2, -2);

      data.push(rowData);
    }

    return data;
  };

  const processCSVData = async (data) => {
    // console.log('Data: ', data);
    try {
      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        // console.log('Row: ', row);
        // console.log('Row: ', i, ': ', row['"Name'], row.Category, row['Product Code'], row['Price ($)']);

        // Check for required fields (name, category, product code, and price)
        if (!row['"Name'] || !row.Category || !row['Product Code'] || !row['Price ($)']) {
          // Handle invalid entry or skip it
          console.log('Invalid entry:', row);
          continue;
        }

        // Construct data object for the API request
        const dataForm = {
          name: row['"Name'],
          product_code: row['Product Code'],
          weight: row.Weight || '',
          length: row['Length (cm)'] || '',
          diameter: row['Diameter (cm)'] || '',
          material: row.Material || '',
          color: row.Color || '',
          price: parseFloat(row['Price ($)']),
          stocks: parseInt(row['Number of Stocks']) || 0,
          description: row.Description || '',
          available: row['"Available Status" "'],
          adminId: admin?.id,
          category: row.Category,
          img: 'https://firebasestorage.googleapis.com/v0/b/craft-export-93ff5.appspot.com/o/images%2F16747124213245.jpg?alt=media&token=dfd98b68-c765-4509-ba86-69b5fb15a0dc', // Add your image processing logic here
        };

        // console.log('Row ', i, ' :', dataForm);

        // Send a POST request to your API to add the product
        const response = await fetch(`${API_SERVICE}/addAdminProduct`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataForm),
        });

        if (response.status !== 200) {
          // Handle failed API request
          console.log('Failed to add product:', row);
        }
      }
    } catch (err) {
      // Handle any errors that occur during processing
      console.log(err);
      throw err;
    }
  };

  return (
    <>
      <Dialog
        open={open3}
        maxWidth="md"
        fullWidth
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Upload Product Data From CSV</DialogTitle>
        <DialogContent>
          <input type="file" accept=".csv" onChange={handleCSVFileUpload} />
          <Button variant="contained" color="primary">
            <a
              href={`/assets/CSVData_Format.csv`}
              download="CSVData_Format.csv"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Download CSV Data Format
            </a>
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCSVSubmit}>Upload Data</Button>
          <Button onClick={handleClose3}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        maxWidth="md"
        fullWidth
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Product</DialogTitle>
        <DialogContent>
          <center>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <img
                  style={{ width: '50%' }}
                  src="https://firebasestorage.googleapis.com/v0/b/craft-export-93ff5.appspot.com/o/images%2F1674657790680VH001b.jpg?alt=media&token=b5cecc87-8431-4b15-92f3-c73f102d7d96"
                />
                <select style={{ padding: '10px', marginTop: '10px' }}>
                  <option selected>Postion 1</option>
                  <option>Postion 2</option>
                  <option>Postion 3</option>
                </select>
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{ width: '50%' }}
                  src="https://firebasestorage.googleapis.com/v0/b/craft-export-93ff5.appspot.com/o/images%2F1674657790676VH001c.jpg?alt=media&token=a0bafbef-38ff-475f-b1a7-23900b4b8477"
                />
                <select style={{ padding: '10px', marginTop: '10px' }}>
                  <option>Postion 1</option>
                  <option selected>Postion 2</option>
                  <option>Postion 3</option>
                </select>
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{ width: '50%' }}
                  src="https://firebasestorage.googleapis.com/v0/b/craft-export-93ff5.appspot.com/o/images%2F1674657790681VH001.jpg?alt=media&token=f16bd175-62ce-460e-a67a-5a398a37fd5c"
                />
                <select style={{ padding: '10px', marginTop: '10px' }}>
                  <option>Postion 1</option>
                  <option>Postion 2</option>
                  <option selected>Postion 3</option>
                </select>
              </Grid>
            </Grid>
          </center>

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="name"
            onChange={handleChange}
            value={state.name}
            label="Name"
          />
          {/* <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant='outlined'
            name="category"
            onChange={handleChange}
            value={state.category}
            label='Category'
          /> */}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              label="Category"
              onChange={(e) => setCategory(e.target.value)}
            >
              {categories.map((category) => {
                return (
                  <MenuItem key={category.id} value={category.categoryName}>
                    {/* <ListItemText primary={category.categoryName}/> */}
                    {/* `${category.categoryName}` */}
                    {category.categoryName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="product_code"
            onChange={handleChange}
            value={state.product_code}
            label="Product Code"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="weight"
            onChange={handleChange}
            value={state.weight}
            label="Weight"
          />

          <Box sx={{ '& > :not(style)': { my: 2, mx: 1, width: '32ch', width: 401 } }} label="Dimensions">
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              name="length"
              onChange={handleChange}
              value={state.length}
              label="Length(in cm)"
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              name="diameter"
              onChange={handleChange}
              value={state.diameter}
              label="Diameter(in cm)"
            />
          </Box>

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="material"
            onChange={handleChange}
            value={state.material}
            label="Material"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="color"
            onChange={handleChange}
            value={state.color}
            label="Color"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            type="number"
            name="price"
            onChange={handleChange}
            value={state.price}
            label="Price( in $ )"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            type="number"
            variant="outlined"
            name="stocks"
            onChange={handleChange}
            value={state.stocks}
            label="Number Stocks"
          />
          <TextField
            multiline
            rows={4}
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="description"
            onChange={handleChange}
            value={state.description}
            label="Description"
          />

          {JSON.stringify(state?.img)}

          {/* <FormControlLabel
            control={<Switch color="primary" />}
            checked={state.available}
            label="Available"
            labelPlacement="start"
            name="available"
            onChange={handleChange}
          /> */}
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="available"
            value={state.available}
            onChange={handleChange}
          >
            <FormControlLabel value={true} control={<Radio />} label="Available" />
            <FormControlLabel value={false} control={<Radio />} label="Not Available" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>Close</Button>
          <Button
            onClick={() => {
              updateProduct2();
            }}
            autoFocus
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Product</DialogTitle>
        <DialogContent>
          <TextField
            required
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="name"
            onChange={handleChange}
            value={state.name}
            label="Name"
          />

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              label="Category"
              onChange={(e) => setCategory(e.target.value)}
            >
              {categories.map((category) => {
                return (
                  <MenuItem key={category.id} value={category.categoryName}>
                    {category.categoryName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            sx={{ mt: 2 }}
            required
            fullWidth
            variant="outlined"
            name="product_code"
            onChange={handleChange}
            value={state.product_code}
            label="Product Code"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="weight"
            onChange={handleChange}
            value={state.weight}
            label="Weight"
          />
          <Box sx={{ '& > :not(style)': { my: 2, mx: 1, width: '32ch', width: 401 } }} label="Dimensions">
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              name="length"
              onChange={handleChange}
              value={state.length}
              label="Length(in cm)"
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              name="diameter"
              onChange={handleChange}
              value={state.diameter}
              label="Diameter(in cm)"
            />
          </Box>

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="material"
            onChange={handleChange}
            value={state.material}
            label="Material"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="color"
            onChange={handleChange}
            value={state.color}
            label="Color"
          />
          <TextField
            sx={{ mt: 2 }}
            required
            fullWidth
            variant="outlined"
            type="number"
            name="price"
            onChange={handleChange}
            value={state.price}
            label="Price( in $ )"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            type="number"
            variant="outlined"
            name="stocks"
            onChange={handleChange}
            value={state.stocks}
            label="Number Stocks"
          />
          <TextField
            multiline
            rows={4}
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="description"
            onChange={handleChange}
            value={state.description}
            label="Description"
          />
          {/* <FormControlLabel
            control={<Switch color="primary" />}
            checked={state.available}
            label="Available"
            labelPlacement="start"
            name="available"
            onChange={handleChange}
          /> */}
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="available"
            value={state.available}
            onChange={handleChange}
          >
            {/* <FileInput
              name="img"
              label="Choose Image"
              handleInputState={handleInputState}
              type="image"
              value={state.img}
            /> */}
            <FileInput
              name="img"
              label="Choose Image"
              handleInputState={handleInputState}
              type="image"
              files={state.img}
              state={setState}
              value=""
              btnState={setSubmitBtn}
            />
            <FormControlLabel value={true} control={<Radio />} label="Available" />
            <FormControlLabel value={false} control={<Radio />} label="Not Available" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            disabled={submitBtn}
            onClick={() => {
              addProduct();
            }}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Helmet>
        <title> Dashboard: Admin Products | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Admin Products
          </Typography>
          <div>
            <Button onClick={handleClickOpen} variant="contained" sx={{ mr: 2 }}>
              New Product
            </Button>
            <Button onClick={handleClickOpen3} variant="contained">
              Upload From CSV
            </Button>
          </div>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1} justifyContent="center" mb={1}>
          <TextField
            variant="outlined"
            label="Product Name"
            sx={{ width: 350 }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <Button size="large" onClick={searchProducts}>
            Search
          </Button>
          <Button size="large" onClick={resetSeacrh}>
            Reset
          </Button>
        </Stack>

        {loading ? (
          <>
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="center" mb={1} mt={4}>
              <Skeleton variant="rounded" width={210} height={228} />
              <Skeleton variant="rounded" width={210} height={228} />
              <Skeleton variant="rounded" width={210} height={228} />
              <Skeleton variant="rounded" width={210} height={228} />
              <Skeleton variant="rounded" width={210} height={228} />
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1} justifyContent="center" mb={1} mt={4}>
              <Skeleton variant="rounded" width={210} height={228} />
              <Skeleton variant="rounded" width={210} height={228} />
              <Skeleton variant="rounded" width={210} height={228} />
              <Skeleton variant="rounded" width={210} height={228} />
              <Skeleton variant="rounded" width={210} height={228} />
            </Stack>
          </>
        ) : (
          <>
            {productsData?.length === 0 ? (
              <center>
                <Typography variant="h6" sx={{ mt: 6 }}>
                  No products found
                </Typography>
              </center>
            ) : (
              <>
                <Stack
                  direction="row"
                  flexWrap="wrap-reverse"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ mb: 5 }}
                >
                  {/* <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                    <ProductFilterSidebar
                      openFilter={openFilter}
                      onOpenFilter={handleOpenFilter}
                      onCloseFilter={handleCloseFilter}
                    />
                    <ProductSort />
                  </Stack> */}
                </Stack>

                <AdminProductList
                  copyProduct={copyProduct}
                  updateProduct={updateProduct}
                  updateProduct2={updateProduct2}
                  deleteProduct={deleteProduct}
                  // products={productsData.filter((product) =>
                  //   product.name.toLowerCase().includes(searchQuery.toLowerCase())
                  // )}
                  products={productsData}
                  setloading={setloading}
                  getProduct={getAllProducts}
                />

                <Stack direction="row" alignItems="center" spacing={1} justifyContent="center" mb={1} mt={4}>
                  {/* <Pagination count={11} color="primary" /> */}
                  <Pagination
                    count={Math.ceil(allData.length / PRODUCTS_PER_PAGE)}
                    color="primary"
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </Stack>
              </>
            )}
          </>
        )}

        <ProductCartWidget />
      </Container>
    </>
  );
}
