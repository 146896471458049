import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_SERVICE } from 'src/config';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { Alert, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Logo from '../components/logo';

import {useLocation} from 'react-router-dom';

export default function Ownerlogin() {
  const [Userdata,setUserdata ] = React.useState({ fname: '',lname : '',email : '' });
    const location = useLocation();
    const navigate = useNavigate();

const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(location.state)
  console.log(Userdata);
 const tobeSaved= {
         password:Userdata.password,
         email:Userdata.email

     
  }
  console.log(tobeSaved)

  console.log(`${API_SERVICE}/Ownerlogin`)
  axios.post(`${API_SERVICE}/Ownerlogin`, tobeSaved)
  .then((response) => {
    console.log(response);

const type={
  type:"owner"
}
const tobestored=[];
tobestored.push(response.data);
tobestored.push(type);
console.log(tobestored)
if (response.data.Owner) {
  localStorage.setItem('token', JSON.stringify( tobestored))
  const token=localStorage.getItem('token')
  console.log(token)

  console.log('Login successful')
  navigate('/dashboard');
} 
else if (response.status="error"){
  console.log('invalid ')
  alert('Please check your username and password')
}
  })
  .catch((error) => {
    console.log(error);
  })
  console.log("sent")

}
  return (
    <>
      <Container maxWidth="sm" sx={{ mt: 10 }} >
        <Card>
          <CardContent>
            <center>
              <Logo />
              <h3>Owners Sign In</h3>
            </center>
              <form className='' onSubmit={handleSubmit}>
                <TextField sx={{ mb: 2 }} fullWidth id="outlined-basic" variant="outlined" name='email' label="Email Address" onChange={(e) => setUserdata({ ...Userdata,email : e.target.value })} />
                <TextField sx={{ mb: 2 }} fullWidth id="outlined-basic" variant="outlined" name='password' label="Password"type='password'  onChange={(e) => setUserdata({ ...Userdata,password : e.target.value })}  />
                <Button
                  variant='contained'
                  size="large"
                  type="submit"
                  sx={{ float: 'right', ml: 2, mb: 2 }}
                >
                  LOGIN
                </Button>
                <br />
                <br />
              </form>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}
