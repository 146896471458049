import { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import { API_SERVICE } from 'src/config';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  // },
  // {
  //   label: 'Profile',
  //   icon: 'eva:person-fill',
  // },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [Name, setName] = useState('');
  
  const Navigate = useNavigate();
  
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  
  const [Email, setEmail] = useState('');
  const handleClose = () => {
    setOpen(null);
  };
  useEffect(() => {
    const token1 = localStorage.getItem('token')

    var token = JSON.parse(token1);
    if(!token){
				Navigate('/Customerlogin')

    }
    console.log(token)
    setEmail(token[2])
    console.log(typeof(token[2])); 
    console.log(`${API_SERVICE}/getName/${Email}`);

    axios
    .get(`${API_SERVICE}/getName/${token[2]}`)
    .then((response) => {
setName(response.data.fname)
      console.log(response)
      // orders=response.data
    })
    .catch((err) => { 
       console.log(err);
    });
  }, [])



  const handleLogout=()=>{

		localStorage.removeItem('token') 
    
    Navigate('/Customerlogin')
    
   };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {Name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {Email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
{/* 
        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
