import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// const firebaseConfig = {
//     apiKey: "AIzaSyDGpc3IFaTYM-3CQnF6YHdapasZBcpZrEo",
//     authDomain: "evencloud-26d32.firebaseapp.com",
//     databaseURL: "https://evencloud-26d32.firebaseio.com",
//     projectId: "evencloud-26d32",
//     storageBucket: "evencloud-26d32.appspot.com",
//     messagingSenderId: "599725599274",
//     appId: "1:599725599274:web:7dfdb2ac4afbdd7da1f153",
//     measurementId: "G-0B9H8156Y8"
//   };

const firebaseConfig = {
  apiKey: 'AIzaSyAKJSoEjZg6gDopLXy5uTegX3sm1yyq9S0',
  authDomain: 'craft-export-93ff5.firebaseapp.com',
  databaseURL: 'https://craft-export-93ff5.firebaseio.com',
  projectId: 'craft-export-93ff5',
  storageBucket: 'craft-export-93ff5.appspot.com',
  messagingSenderId: '666616161219',
  appId: '1:666616161219:web:31f3501eca9ab34f1f1376',
  measurementId: 'G-JZN0RVYJYS',
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app, 'gs://craft-export-93ff5.appspot.com');
export default storage;

//gs://evencloud-26d32.appspot.com
// import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";

// const firebaseConfig = {
// 	apiKey: "AIzaSyCiWSeNE7Ec9bBCn26if4RgJ6IY8bErudg",
// 	authDomain: "uploading-files-8f29f.firebaseapp.com",
// 	projectId: "uploading-files-8f29f",
// 	storageBucket: "uploading-files-8f29f.appspot.com",
// 	messagingSenderId: "799176730689",
// 	appId: "1:799176730689:web:b6adc02a8cc0ac942cfec0"
//   };

// const app = initializeApp(firebaseConfig);
// const storage = getStorage(app, 'gs://uploading-files-8f29f.appspot.com');
// export default storage;
