import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_SERVICE } from 'src/config';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import {useLocation} from 'react-router-dom';
import { CheckBox, Message } from '@mui/icons-material';
import { Alert, Card, CardContent, Checkbox } from '@mui/material';
import Logo from '../components/logo';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function Customersignup() {
  const [Userdata,setUserdata ] = React.useState({ fname: '',lname : '',name:'', password :'' ,email : ''  ,confpass:'' , agree:false});
    const navigate = useNavigate();
const[message ,setMessage]=useState("");
const[warning ,setwarning]=useState(false);

const handleSubmit = async (e) => {
      e.preventDefault();
      if(!Userdata.agree){
        setwarning(true)
        
               setMessage("Please agree to terms and conditions")
          }
     else if(Userdata.password.length<8){
setwarning(true)

       setMessage("password must be greater than 8")
  }
  else if(!Boolean(Userdata.password.match(/[A-Z]/))){
    setwarning(true)
    setMessage("MUST INCLUDE AN UPPERCASE LETTER")
  }  
  else if(!Boolean(Userdata.password.match(/[a-z]/))){
    setwarning(true)
    setMessage("MUST INCLUDE AN LOWERCASE LETTER")
  } 
  else if(!Boolean(Userdata.password.match(/[0-9]/))){
    setwarning(true)
    setMessage("MUST INCLUDE AN NUMBER")
  }
  else if(!Boolean(Userdata.email.endsWith("com"))){
    setwarning(true)
    setMessage("PLEASE ENTER VALID EMAIL")
  }
  else{
    const tobeSaved= {
          fname:Userdata.fname,
          lname:Userdata.lname,
            password:Userdata.password,
            email:Userdata.email,
   
        
     }

    console.log(tobeSaved)
    
    console.log(`${API_SERVICE}/Customerregister`)
    axios.post(`${API_SERVICE}/Customerregister`, tobeSaved)
    .then((response) => {
      console.log(response);
      
      // Handle data
    })
    .catch((error) => {
      console.log(error);
    })
    
    navigate('/Customerlogin');
  }

}
  return (
    <>
    <Container maxWidth="sm" sx={{ mt: 10 }} >
      {warning ? (
    <Alert severity="error">{message}</Alert>
      ) : (
        <div></div>
      )}
      
      <Card>
        <CardContent>
          <center>
            <Logo />
            <h3>Business Sign up</h3>
          </center>
          <form className='' onSubmit={handleSubmit}>
          <TextField sx={{ mb: 2 ,mt: 2 }} fullWidth id="outlined-basic" variant="outlined" name='fname' label="First Name" onChange={(e) => setUserdata({ ...Userdata,fname : e.target.value })} />
          <TextField sx={{ mb: 2 ,mt: 2 }} fullWidth id="outlined-basic" variant="outlined" name='lname' label="Last Name" onChange={(e) => setUserdata({ ...Userdata,lname : e.target.value })} />
        <TextField sx={{ mb: 2 }} type="email" fullWidth id="outlined-basic" variant="outlined" name='email' label="Email Address" onChange={(e) => setUserdata({ ...Userdata,email : e.target.value })} />
        <TextField sx={{ mb: 2 }} fullWidth id="outlined-basic" variant="outlined" name='password' label="Password"type='password'  onChange={(e) => setUserdata({ ...Userdata,password : e.target.value })}  />
        
        <TextField sx={{ mb: 2 }} type="password" fullWidth id="outlined-basic" variant="outlined" label="Confirm Password" onChange={(e) => setUserdata({ ...Userdata   ,confpass : e.target.value })} />
        {/* <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="Acccept terms and conditions"
            // value={state.available}
            onChange={(e) => setUserdata({ ...Userdata,agree : e.target.value })}
          >	  
          <FormControlLabel value={true} control={<Radio />} label="Agree to terms and condtions" />
          {/* <FormControlLabel value={false} control={<Radio />} label="Not Available" /> */}
        {/* </RadioGroup>  */}
        <Checkbox
  checked={Userdata.agree}
  onChange={(e) => setUserdata({ ...Userdata,agree : !Userdata.agree })}
  inputProps={{ 'aria-label': 'controlled' }}
/>Acccept terms and conditions

        <Button
          variant='contained'
          size="large"
          type="submit"
          sx={{ float: 'right', ml: 2 }}
        >
          SIGN UP
        </Button>
        
        <Button
          variant='outlined'
          size="large"
          sx={{ float: 'right' }}
        >
          Cancel
        </Button>
        <br />
        <br />
            <a style={{ marginTop: '100px' }} href="/Customerlogin">Already have an account Login Now</a>
          </form>
        </CardContent>
      </Card>


    </Container>
    </>
  )
}
