import React, { useEffect, useState } from 'react';
import { API_SERVICE } from 'src/config';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Divider,
  DialogActions,
  Box,
  Pagination,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CheckoutOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [orderStatusupdated, setordreStatusupdated] = useState(false);

  //------------ Implement the pagination-------------------X
  const pageSize = 10;
  const [pagination, setPagination] = useState({ count: 0, from: 0, to: pageSize })

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${API_SERVICE}/getAllCheckoutOrders`);
        const data = await response.json();
        // console.log("check the data what we fetch ", data);
        let dataLen = data.length;
        let pageData = data.slice(pagination.from, pagination.to);

        let resData = {
          count: dataLen, pageData
        }
        // console.log('check the res data is ',resData);

        setPagination({ ...pagination, count: resData.count });

        setOrders(resData.pageData);

      } catch (error) {
        console.log(error);
        // Handle any errors that occur during the fetch request
      }
    };

    fetchOrders();
  }, [orderStatusupdated, pagination.form, pagination.to]);

  //------------------- Handling the pagination to move the products----------X
  const handlePagination = (e, page) => {
    let form = (page - 1) * pageSize;
    let to = (page - 1) * pageSize + pageSize;

    setPagination({ ...pagination, from: form, to: to });
  }

  // console.log('Orders: ', orders);

  const handleViewMore = (order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
    setordreStatusupdated(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOrderCompleted = async () => {
    const orderId = selectedOrder._id;
    try {
      const response = await fetch(`${API_SERVICE}/updateOrderStatus/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderCompleted: true }),
      });

      if (response.ok) {
        // Order status updated successfully
        console.log('Order status updated');
        setordreStatusupdated(true);
      } else {
        // Error updating order status
        console.log('Error updating order status');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '15%' }}>Name</TableCell>
              <TableCell style={{ width: '15%' }}>Email</TableCell>
              <TableCell style={{ width: '10%' }}>Phone Number</TableCell>
              <TableCell style={{ width: '15%' }}>Notes</TableCell>
              <TableCell style={{ width: '7%' }}>Cart Total</TableCell>
              <TableCell style={{ width: '10%' }}>Order Status</TableCell>
              {/* <TableCell style={{ width: '10%' }}>No of Items</TableCell> */}
              <TableCell style={{ width: '13%' }}>View More</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order._id}>
                <TableCell style={{ width: '15%' }}>{order.user.fullName}</TableCell>
                <TableCell style={{ width: '15%' }}>{order.user.email}</TableCell>
                <TableCell style={{ width: '10%' }}>{order.user.phoneNumber}</TableCell>
                <TableCell style={{ width: '15%' }}>{order.user.notes}</TableCell>
                <TableCell style={{ width: '17%' }}>${order.cartTotal}</TableCell>
                <TableCell style={{ width: '10%' }}>{order.orderCompleted ? 'Completed' : 'Pending'}</TableCell>
                {/* <TableCell style={{ width: '10%',}}>
                  {order.cartItems.length}
                </TableCell> */}
                <TableCell style={{ width: '13%' }}>
                  <Button variant="outlined" onClick={() => handleViewMore(order)}>
                    View More
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* --------------- Our pagination section to show the pages of the products checkouts  */}
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} margin={"12px auto"} >
        {/* Our pagination to show the pages counts  */}
        <Pagination
          count={Math.ceil(pagination.count / pageSize)}
          onChange={handlePagination}
        />
      </Box>

      {/* Dialog for displaying order details */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
        {selectedOrder && (
          <>
            <DialogTitle
              disableTypography
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <span>Order Details</span>
              <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            {/* Customer Details Section */}
            <DialogContent>
              <strong>Customer Details:</strong>
              <DialogContentText>
                <strong>Full Name:</strong> {selectedOrder.user.fullName}
                <br />
                <strong>Email:</strong> {selectedOrder.user.email}
                <br />
                <strong>Address:</strong> {selectedOrder.user.address}
                <br />
                <strong>City:</strong> {selectedOrder.user.city}
                <br />
                <strong>Zip Code:</strong> {selectedOrder.user.zipCode}
                <br />
                <strong>Country:</strong> {selectedOrder.user.country}
                <br />
                <strong>Phone Number:</strong> {selectedOrder.user.phoneNumber}
                <br />
                <strong>Notes:</strong> {selectedOrder.user.notes}
              </DialogContentText>
            </DialogContent>

            {/* Divider */}
            <Divider />

            {/* Order Details Section */}
            <DialogContent>
              <strong>Order Items:</strong>
              <br />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product ID</TableCell>
                      <TableCell>Price Per Item</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedOrder.cartItems.map((item) => (
                      <TableRow key={item.productId}>
                        <TableCell>{item.productId}</TableCell>
                        <TableCell>{item.pricePerItem}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{item.totalPrice}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>

            {/* Divider */}
            <Divider />

            {/* Cart Total */}
            <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <DialogContentText>
                <strong style={{ color: '#000000' }}>Cart Total:</strong> ${selectedOrder.cartTotal}
              </DialogContentText>
              <Button variant="contained" onClick={handleOrderCompleted}>
                Mark Order Completed
              </Button>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export default CheckoutOrders;
