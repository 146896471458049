import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_SERVICE } from 'src/config';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Alert, Card, CardContent } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Logo from '../components/logo';
import { Stack } from '@mui/system';

export default function Customerlogin() {
  const [Userdata, setUserdata] = React.useState({ fname: '', lname: '', email: '' });
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [warning, setwarning] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const tobeSaved = {
      password: Userdata.password,
      email: Userdata.email,
    };
    console.log(tobeSaved);

    console.log(`${API_SERVICE}/Customerlogin`);
    axios
      .post(`${API_SERVICE}/Customerlogin`, tobeSaved)
      .then((response) => {
        console.log(response);

        // console.log(response.Customer);
        const type = {
          type: 'customer',
        };
        const tobestored = [];
        tobestored.push(response.data);
        tobestored.push(type);
        tobestored.push(Userdata.email);
        if (response.data.Customer) {
          localStorage.setItem('token', JSON.stringify(tobestored));
          
          const token = localStorage.getItem('token');

          console.log('Login successful');
          // window.location.href = '/dashboard'
          navigate('/dashboard');
        } else if ((response.status = 'error')) {
          console.log(response.data.Customer);
          setwarning(true);
          setMessage('Please check your username and password');
        }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log('sent');
  };
  return (
    <>
      <Stack>
        <Container maxWidth="sm" sx={{ mt: 10 }}>
          {warning ? <Alert severity="error">{message}</Alert> : <div></div>}
          <Card>
            <CardContent>
              <center>
                <Logo />
                <h3>Business Sign In</h3>
              </center>
              <form className="" onSubmit={handleSubmit}>
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  name="email"
                  label="Email Address"
                  onChange={(e) => setUserdata({ ...Userdata, email: e.target.value })}
                />
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  name="password"
                  label="Password"
                  type="password"
                  onChange={(e) => setUserdata({ ...Userdata, password: e.target.value })}
                />
                <Button variant="contained" size="large" type="submit" sx={{ float: 'right', ml: 2 }}>
                  LOGIN
                </Button>

                <Button size="large" variant="outlined" sx={{ float: 'right' }}>
                  Cancel
                </Button>
                <br />
                <br />
                <a style={{ marginTop: '100px' }} href="/Customersignup">
                  Don't have an account Register Now
                </a>
              </form>
            </CardContent>
          </Card>
        </Container>
      </Stack>
    </>
  );
}
