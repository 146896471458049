import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { useEffect } from 'react';
// @mui
import {
  Box,
  Card,
  Table,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  CircularProgress,
  Tooltip,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { API_SERVICE } from '../config';

import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import { isExpired, decodeToken } from "react-jwt";


// ----------------------------------------------------------------------


export default function CategoryPage() {

  const [open, setOpen] = useState(null);

  const [dialogOpen2, setDialogOpen2] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [page, setPage] = useState(0);

  const [loading, setloading] = useState(true);

  // const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  // const [orderBy, setOrderBy] = useState('name');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [categoryData, setcategoryData] = useState([]);

  const [currCategory, setCurrCategory] = useState([]);

  const [categoryId, setCategoryId] = useState("")

  const [state, setState] = useState({
    categoryName: "",
    status: false
  })
  useEffect(() => {
    const token1 = localStorage.getItem('token')
    var token = JSON.parse(token1);
    // console.log(token) 
    if(!token){
      console.log("no token")
      // Navigate('/Customerlogin')
  
    }
    else if (token) {
      const owner=decodeToken(token[0].Owner)
      const customer =decodeToken(token[0].Customer)
      if (!customer) {
        // console.log("invalid token")
        // localStorage.removeItem('token')
        // Navigate('/Ownerlogin')
      } 
      else {
        // console.log('valid user')
       
      }
    }
  }, [])
  const addCategory = async () => {
    setloading(true);
    try {
      const response = await fetch(`${API_SERVICE}/addCategory`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...state, userId: 'fenfjsnfjewnjnelleslnlenfefneflk' }),
      });
      if (response.status === 200) {
        handleClose();
        getAllCategories();
        setState({
          categoryName: "",
          status: false
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    try {
      const response = await fetch(`${API_SERVICE}/getallcategories/fenfjsnfjewnjnelleslnlenfefneflk`);
      const data = await response.json();
      setcategoryData(data);
      console.log(data);
      setloading(false);
    } catch (err) {
      console.log(err);
    }
  }

  const updateCategory = async (category) => {
    setState({
      categoryName: category.categoryName,
      status: category.status
    })
    setCategoryId(category?._id);
    handleClickOpen2();
  }

  const updateCategory2 = async () => {
    setloading(true);
    try {
      const response = await fetch(`${API_SERVICE}/updatecategory/${categoryId}/fenfjsnfjewnjnelleslnlenfefneflk`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...state })
      });
      getAllCategories();
      handleClose2();
      setState({
        categoryName: "",
        status: false,
      });
    } catch (err) {
      console.log(err);
    }
  }

  const deleteCategory = async (id) => {
    console.log(id);
    setloading(true);
    try {
      const response = await fetch(`${API_SERVICE}/deletecategory/${id}/fenfjsnfjewnjnelleslnlenfefneflk`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      getAllCategories();
      handleCloseMenu();
    } catch (err) {
      console.log(err);
    }
  }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleClickOpen2 = () => {
    setDialogOpen2(true);
  };

  const handleClose2 = () => {
    +
    setDialogOpen2(false);
  };

  // const handleCurrCategory = (cat) => {
  //   setCurrCategory(cat);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = USERLIST.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChange = (event) => {
    console.log(event.target.name);
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categoryData.length) : 0;

  return (
    <>
      <Dialog
        open={dialogOpen}
        maxWidth='md'
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Category
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 2, mb: 5 }}
            fullWidth
            variant='outlined'
            name="categoryName"
            onChange={handleChange}
            value={state.categoryName}
            label='Category Name'
          />

          <FormLabel id="demo-controlled-radio-buttons-group">Status</FormLabel>
          <RadioGroup
            sx={{ mt: 2 }}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="status"
            value={state.status}
            label="Status"
            onChange={handleChange}
          >
            <FormControlLabel value={true} control={<Radio />} label="Enable" />
            <FormControlLabel value={false} control={<Radio />} label="Disable" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={addCategory} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen2}
        maxWidth='md'
        fullWidth
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">
          Update Category
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 2, mb: 5 }}
            fullWidth
            variant='outlined'
            name="categoryName"
            onChange={handleChange}
            value={state.categoryName}
            label='Category Name'
          />

          <FormLabel id="demo-controlled-radio-buttons-group">Status</FormLabel>
          <RadioGroup
            sx={{ mt: 2 }}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="status"
            value={state.status}
            label="Status"
            onChange={handleChange}
          >
            <FormControlLabel value={true} control={<Radio />} label="Enable" />
            <FormControlLabel value={false} control={<Radio />} label="Disable" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>Close</Button>
          <Button onClick={updateCategory2} autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Helmet>
        <title> Category | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Category
          </Typography>
          <Button onClick={handleClickOpen} variant="contained">
            New Category
          </Button>
        </Stack>
        <Card>

          <Box sx={{ minWidth: 500 }}>

            {loading ? (
              <center>
                <CircularProgress sy={{ mt: 9 }} sx={{ mt: 1 }} />
              </center>
            ) : (
              <>
                {categoryData?.length === 0 ? (
                  <center>
                    <Typography variant="h6" sx={{ mt: 6 }}>
                      No categories found
                    </Typography>
                  </center>
                ) : (
                  <>
                    <Table>
                      <TableHead >
                        <TableRow>
                          <TableCell>Category</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {categoryData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category) => {
                          return (
                            <TableRow
                              key={category._id}
                            >
                              <TableCell >{category.categoryName}</TableCell>
                              <TableCell>
                                {category.status?
                                <Label color={'success'}>Enable</Label>
                                :
                                <Label color={'error'}>Disable</Label>
                                }
                              </TableCell>
                              <TableCell align="right">
                                {/* <IconButton size="large" color="inherit" onClick={()=>{setCurrCategory(category); handleOpenMenu;}}>
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton> */}
                                <Tooltip title="Edit">
                                  <IconButton
                                    color="inherit"
                                    aria-label="upload picture"
                                    //component="span"
                                    onClick={() => {
                                      updateCategory(category)
                                    }}
                                  >
                                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 0 }} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={() => { deleteCategory(category._id) }


                                    }
                                    color="error"
                                    aria-label="upload picture"
                                  //component="span"
                                  >
                                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 0 }} />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>

                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </>
                )}
              </>
            )}
          </Box>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={categoryData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
        //onClick={()=>{updateCategory(currCategory)}}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}
        //onClick={deleteCategory(currCategory?._id)} 
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
