import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ copyProduct, updateProduct,updateProduct2, deleteProduct, products,setloading,getProduct,...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product._id} item xs={12} sm={6} md={3}>
          <ShopProductCard copyProduct={copyProduct} updateProduct={updateProduct} updateProduct2={updateProduct2} deleteProduct={deleteProduct} product={product} setloading={setloading} getProduct={getProduct}/>
        </Grid>
      ))}
    </Grid>
  );
}
