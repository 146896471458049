import { useRef, useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import storage from './Firebase';
// import check from "../../check.png";
import styles from './styles.module.css';
import check from './check.png';

const FileInput = ({ name, label, state, files, value, type, handleInputState, btnState, ...rest }) => {
  const inputRef = useRef();
  const [progress, setProgress] = useState(0);
  const [progressShow, setProgressShow] = useState(false);
  const [urls, seturls] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    state((prev) => {
      return { ...prev, urls };
    });
  }, [urls]);

  const handleUpload = () => {
    setProgressShow(true);
    btnState(true)
    files?.forEach((file) => {
      const fileName = new Date().getTime() + file.name;
      const storageRef = ref(storage, type === 'audio' ? `/audio/${fileName}` : `/images/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const uploaded = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(uploaded);
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            seturls((prev) => [...prev, url]);
          });
        }
      );
    });
  };

  return (
    <div className={styles.container}>
      <input
        type="file"
        ref={inputRef}
        multiple={true}
        onChange={(e) => {
          setImages(Object.values(e.currentTarget.files));
          handleInputState(name, e.currentTarget.files);
        }}
        value={value}
        className={styles.input}
        {...rest}
      />
      <button
        style={{ marginRight: '8px' }}
        type="button"
        onClick={() => inputRef.current.click()}
        className={styles.button}
      >
        {label}
      </button>
      <div
        style={{ width: '75%', display: 'flex', justifyContent: 'space-evenly', overflow: 'auto', marginRight: '8px' }}
      >
        {type === 'image' &&
          images.length !== 0 &&
          images.map((file) => {
            return (
              <img
                key={file.name}
                src={URL.createObjectURL(file)}
                alt="file"
                className={styles.preview_img}
                style={{ marginRight: '10px' }}
              />
            );
          })}
        {type === 'audio' && value && (
          <audio src={typeof value === 'string' ? value : URL.createObjectURL(value)} controls />
        )}
      </div>
      {!progressShow && (
        <button onClick={handleUpload} className={styles.button}>
          Upload
        </button>
      )}
      {/* {value !== null && !progressShow && typeof value !== 'string' && (
        <button onClick={handleUpload} className={styles.button}>
          Upload
        </button>
      )} */}
      {progressShow && progress < 100 && (
        <div className={styles.progress_container}>
          <p>{progress}%</p>
        </div>
      )}
      {progress === 100 && (
        <div className={styles.progress_container}>
          <img src={check} alt="check circle" className={styles.check_img} />
        </div>
      )}
    </div>
  );
};

export default FileInput;
