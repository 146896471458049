

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_SERVICE } from 'src/config';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Alert, Stack } from '@mui/material';
import {useLocation} from 'react-router-dom';
import { Message } from '@mui/icons-material';
import { isExpired, decodeToken } from "react-jwt";

export default function AccountSettings() {
    const [Userdata,setUserdata ] = React.useState({ fname: '',lname : '',email : '' ,phone:'',country:'', state:'' ,company:'' });
    const[pass,setpass]=React.useState({oldpass:'', newpass:'' , confnewpass:''})
    const navigate = useNavigate();
    const[message ,setMessage]=useState("");
    const[emailwarning ,setwarning]=useState(false);
    const[warning ,setemailwarning]=useState(false);
    useEffect(() => {
        const token1 = localStorage.getItem('token')
        var token = JSON.parse(token1);
        console.log(token) 
        if (token) {
          const owner=decodeToken(token[0].Owner)
          const customer =decodeToken(token[0].Customer)
          if (!customer) {
            console.log("invalid token")
            localStorage.removeItem('token')
            Navigate('/Customerlogin')
          } 
          else {
            console.log('valid user')

            axios
            .get(`${API_SERVICE}/getName/${token[2]}`)
            .then((response) => {
              setUserdata(
            {
              fname:response.data.fname,
              lname:response.data.lname,
             
              phone:response.data.phone,
              country:response.data.country,
              state:response.data.state,
                email:response.data.email,
               company :response.data.company,
       
            }

              )
              console.log(response)
              console.log(Userdata)
        // setName(response.data.name)
              // orders=response.data
            })
            .catch((err) => { 
               console.log(err);
            });
     
          }
        }
      }, [])
    const handleSubmit = async (e) => {
        const token1 = localStorage.getItem('token')
        var token = JSON.parse(token1)
        e.preventDefault();
      
     if(!Boolean(Userdata.email.endsWith("com"))){
      setemailwarning(true)
      setMessage("PLEASE ENTER VALID EMAIL")
    }
    else{
      const tobeSaved= {
            fname:Userdata.fname,
            lname:Userdata.lname,
           
            phone:Userdata.phone,
            country:Userdata.country,
            state:Userdata.state,
            email:Userdata.email,
       
             company :Userdata.company,
     
          
       }
  
      console.log(tobeSaved)
      
      console.log(`${API_SERVICE}/updateInfo`)
      axios.post(`${API_SERVICE}/updateInfo/${token[2]}`, tobeSaved)
      .then((response) => {
        console.log(response);
        
        // Handle data
      })
      .catch((error) => {
        console.log(error);
      })
      
      navigate('/dashboard');
    }
  
  }



  ///change password
  const changePassword = async (e) => {
    e.preventDefault();
    const token1 = localStorage.getItem('token')
    var token = JSON.parse(token1)
    if(pass.newpass.length<8){
        console.log(pass.newpass.length)
setwarning(true)

     setMessage("password must be greater than 8")
}
else if(!Boolean(pass.newpass.match(/[A-Z]/))){
  setwarning(true)
  setMessage("MUST INCLUDE AN UPPERCASE LETTER")
}  
else if(!Boolean(pass.newpass.match(/[a-z]/))){
  setwarning(true)
  setMessage("MUST INCLUDE AN LOWERCASE LETTER")
} 
else if(!Boolean(pass.newpass.match(/[0-9]/))){
  setwarning(true)
  setMessage("MUST INCLUDE AN NUMBER")
}
else if(pass.newpass!=pass.confnewpass){
    setwarning(true)
    setMessage("PASSWORD AND CONFIRM PASSWORD SHOULD BE SAME")
}
else{
  const tobeSaved= {
   opass:pass.oldpass,
          npassword:pass.newpass   
 
      
   }

  console.log(tobeSaved)
  
  console.log(`${API_SERVICE}/changePassword/${token[2]}`)
  axios.post(`${API_SERVICE}/changePassword/${token[2]}`, tobeSaved)
  .then((response) => {
    console.log(response);
    // alert(response)
    if(response.data=="successful")
    {

          navigate('/dashboard');
    }
    else{
        setwarning(true)
    setMessage("INVALID OLD PASSWORD")
    }
    // Handle data
  })
  .catch((error) => {
    console.log(error);
  })
  

}

}
  return (
    <>
       <form className='' onSubmit={handleSubmit}>
        <Stack
        direction={{ xs: 'column', sm: 'row' }}
    
        >
        <TextField sx={{ mb: 2 ,mt: 2 }} fullWidth id="outlined-basic" variant="outlined" value={Userdata.fname} name='name' label="First Name" onChange={(e) => setUserdata({ ...Userdata,fname : e.target.value })} />
        <TextField sx={{ mb: 2 ,mt: 2 }} fullWidth id="outlined-basic" value={Userdata.lname} variant="outlined" name='name' label="Last Name" onChange={(e) => setUserdata({ ...Userdata,lname : e.target.value })} />
        </Stack>
        <Stack
        direction={{ xs: 'column', sm: 'row' }}
    
        >
<TextField sx={{ mb: 2 ,mt: 2 }} fullWidth id="outlined-basic" variant="outlined" value={Userdata.country} label="Country" onChange={(e) => setUserdata({ ...Userdata,country : e.target.value })} />
        <TextField sx={{ mb: 2 ,mt: 2 }} fullWidth id="outlined-basic" variant="outlined"  label="State"value={Userdata.state} onChange={(e) => setUserdata({ ...Userdata,state : e.target.value })} />
        </Stack>
        <Stack
        direction={{ xs: 'column', sm: 'row' }}
    
        >
          
        </Stack>
        <Stack
        direction={{ xs: 'column', sm: 'row' }}
    
        >
          
        <TextField sx={{ mb: 2 ,mt: 2 }} fullWidth id="outlined-basic" variant="outlined"  label="Phone Number" value={Userdata.phone} onChange={(e) => setUserdata({ ...Userdata,phone : e.target.value })} />
        <TextField sx={{ mb: 2 ,mt: 2 }} fullWidth id="outlined-basic" variant="outlined"  label="Company Name" value={Userdata.company} onChange={(e) => setUserdata({ ...Userdata,company : e.target.value })} />
        </Stack>
        
    
        <TextField 
        
        disabled={true}
        sx={{ mb: 2 }} type="email" fullWidth id="outlined-basic" variant="outlined" name='email' value={Userdata.email} label="Email Address" onChange={(e) => setUserdata({ ...pass,email : e.target.value })} />
       
        
        <Button
          variant='contained'
          size="large"
          type="submit"
          sx={{ float: 'right', ml: 2 }}
        >
          CHANGE CREDENTIALS   
        </Button>
        </form>
 
 <h1 >CHANGE PASSSWORD</h1>
 <form className='' onSubmit={changePassword}>
 
 {warning ? (
    <Alert severity="error">{message}</Alert>
    ) : (
        <div></div>
  
      )}
        <TextField sx={{ mb: 2 }} type="password" fullWidth id="outlined-basic" variant="outlined" label="Old Password" onChange={(e) => setpass({ ...pass,oldpass : e.target.value })} />
        <TextField sx={{ mb: 2 }} type="password" fullWidth id="outlined-basic" variant="outlined" label="New Password" onChange={(e) => setpass({ ...pass,newpass : e.target.value })} />
        <TextField sx={{ mb: 2 }} type="password" fullWidth id="outlined-basic" variant="outlined" label="Confirm Password" onChange={(e) => setpass({ ...pass,confnewpass : e.target.value })} />
        <Button
          variant='contained'
          size="large"
          type="submit"
          sx={{ float: 'right', ml: 2 }}
        >
          CHANGE PASSSWORD   
        </Button>
        </form>

    </>
  )
}
