import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_SERVICE } from 'src/config';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useLocation } from 'react-router-dom';

export default function CustomerForm() {
  const [Userdata, setUserdata] = React.useState({ fname: '', lname: '', email: '' });
  const location = useLocation();
  console.log(location.state.data);
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [warning, setwarning] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Userdata?.fname == '' || Userdata?.lname == '' || Userdata?.email == '') {
      alert('Please fill the fields');
    } else {
      const token1 = localStorage.getItem('token');
      var token = JSON.parse(token1);
      console.log(token[2]);
      // console.log(location.state)
      // console.log(Userdata);
      const tobeSaved = {
        fname: Userdata.fname,
        lname: Userdata.lname,
        email: Userdata.email,
        BillingAddress: Userdata.BillingAddress,
        CompanyName: Userdata.CompanyName,
        CompanyWebsite: Userdata.CompanyWebsite,
        productName: location.state.data[0].name,
        productCode: location.state.data[0].product_code,
        category: location.state.data[0].category,
        description: location.state.data[0].description,
        diameter: location.state.data[0].diameter,
        length: location.state.data[0].length,
        material: location.state.data[0].material,
        weight: location.state.data[0].weight,
        stocks: location.state.data[0].stocks,
        color: location.state.data[2].color,
        cost: location.state.data[0].price,
        qty: location.state.data[1].qty,
        img: location.state.data[0].img,
        customerEmail: token[2],
      };
      console.log(tobeSaved);

      console.log(`${API_SERVICE}/addAdminOrderedProduct`);
      axios
        .post(`${API_SERVICE}/addAdminOrderedProduct`, tobeSaved)
        .then((response) => {
          console.log(response.data);

          // Handle data
        })
        .catch((error) => {
          console.log(error);
        });
      console.log('sent');
      setwarning(true);
      setMessage('SUBMITTED');
      navigate('/dashboard/marketplace');
    }
  };
  return (
    <div>
      {warning ? <Alert severity="success">{message}</Alert> : <div></div>}

      <form className="" onSubmit={handleSubmit}>
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          id="outlined-basic"
          variant="outlined"
          name="fname"
          label="First Name"
          onChange={(e) => setUserdata({ ...Userdata, fname: e.target.value })}
        />
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          id="outlined-basic"
          variant="outlined"
          name="lname"
          label="Last Name"
          onChange={(e) => setUserdata({ ...Userdata, lname: e.target.value })}
        />
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          id="outlined-basic"
          variant="outlined"
          name="email"
          label="Email Address"
          onChange={(e) => setUserdata({ ...Userdata, email: e.target.value })}
        />
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          id="outlined-basic"
          variant="outlined"
          name="BillingAddress"
          label="Billing Address"
          onChange={(e) => setUserdata({ ...Userdata, BillingAddress: e.target.value })}
        />
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          id="outlined-basic"
          variant="outlined"
          name="CompanyName"
          label="Company Name"
          onChange={(e) => setUserdata({ ...Userdata, CompanyName: e.target.value })}
        />
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          id="outlined-basic"
          variant="outlined"
          name="CompanyWebsite"
          label="Company Website"
          onChange={(e) => setUserdata({ ...Userdata, CompanyWebsite: e.target.value })}
        />

        <Button variant="contained" type="submit" sx={{ float: 'right', ml: 2 }}>
          Submit order
        </Button>

        <Button variant="outlined" sx={{ float: 'right' }}>
          Cancel
        </Button>

        {/* <input type="text" className="mt-2 form-control" name='fname' placeholder="First name" aria-label="First name"onChange={(e) => setUserdata({ ...Userdata,fname : e.target.value })} />

    <input type="text" className="mt-2 form-control" name='lname' placeholder="Last name" aria-label="Last name"onChange={(e) => setUserdata({ ...Userdata,lname : e.target.value })} />
    <input type="text" className="mt-2 form-control" name='email' placeholder="email" aria-label="email"onChange={(e) => setUserdata({ ...Userdata,email : e.target.value })} /> */}
        {/* <button type="Submit"> SUBMIT</button> */}
      </form>
    </div>
  );
}
