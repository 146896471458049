import React, { useEffect, useState } from 'react';
import { Card, CardContent, Container, Typography } from '@mui/material';
import { API_SERVICE } from 'src/config';

const Messages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Fetch all messages from the API endpoint
    const fetchMessages = async () => {
      try {
        const response = await fetch(`${API_SERVICE}/getAllMessages`);
        if (response.ok) {
          const data = await response.json();
          setMessages(data.messages.reverse());
        } else {
          console.error('Failed to fetch messages');
        }
      } catch (error) {
        console.error('Error occurred while fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  return (
    <Container>
      <Typography variant="h2">All Messages</Typography>
      {messages.map((message) => (
        <Card key={message._id} variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6" component="h2">
              Name: {message.name}
            </Typography>
            <Typography variant="body1" component="p">
              Email: {message.email}
            </Typography>
            <Typography variant="body1" component="p">
              Contact Number: {message.contactNumber}
            </Typography>
            <Typography variant="body1" component="p">
              Message: {message.message}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Created At: {new Date(message.CreatedAt).toLocaleString()}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Container>
  );
};

export default Messages;
