import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack, FormGroup, FormControlLabel, Switch, Button } from '@mui/material';
import CardActions from '@mui/material/CardActions';

import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import { useState } from 'react';
import { set, update } from 'lodash';
import { API_SERVICE } from '../../../config';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopAdminProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopAdminProductCard({ copyProduct, updateProduct,updateProduct2, deleteProduct, product , setloading,getProduct }) {
  var { name, description,product_code,weight,length,diameter,material,color, price, stocks, category,available ,img} = product;

  const [ checked, setChecked]=useState(true);

  const handleChange=(event)=>{
      setChecked(event.target.checked);
      console.log(event.target.checked);
      updateAvailable(event.target.checked);
  }
 
   const updateAvailable=async(status)=>{
    try {
      setloading(true);
      const response = await fetch(`${API_SERVICE}/updateAdminProduct/${product?._id}/dnjcndskjcnjnckncjsnajkcnjsansa`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          category: category,
          product_code: product_code,
          weight: weight,
          length: length,
          diameter: diameter,
          material: material,
          color: color,
          price: price,
          stocks: stocks,
          description: description,
          available: !status,
        })  
      });
      if(response.status===200){
        console.log("Product Updated");
        getProduct();
      }
    } catch (err) {
      console.log(err);
    }
   }
  
  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {console.log(img)}
        <StyledProductImg alt={name} src={img} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {name} - {category}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            Stocks: {stocks}
          </Typography>
        </Link> 

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <FormGroup>
            <FormControlLabel control={<Switch checked={product.available} onChange={()=>updateAvailable(product.available)} />} />
          </FormGroup>
          <Typography variant="subtitle1">
            {fCurrency(price)}
          </Typography>
        </Stack>
      </Stack>
      <CardActions>
        <Button fullWidth color='info' variant='contained' onClick={() => updateProduct(product)} >Edit</Button>
        <Button fullWidth color='primary' variant='outlined' onClick={() => copyProduct(product)} >Copy</Button>
        <Button fullWidth color='error' variant='outlined' onClick={() => deleteProduct(product?._id)} >Delete</Button>
      </CardActions>
    </Card>
  );
}
