import { Container, Paper, Grid, Stack, Box, Typography, Button, TextField } from '@mui/material';


const OrderView = () => {
  return (
    <>
        <Box sx={{ mt: 5, mb: 5, mx: 2.5 }}>
            <Typography variant="h3" sx={{ color: 'text.primary', float: 'left' }}>
                Test Order
            </Typography>

            <Button
                variant='contained'
                color="primary"
                sx={{ float: 'right' }}
            >
                Submit Order
            </Button>

            <Grid sx={{ mt: 4 }} container spacing={3}>
                <Grid item xs={3}>
                    <Paper sx={{ p: 4 }}>
                        <center>
                            <Typography variant="h3" sx={{ color: 'text.primary' }}>
                                1
                            </Typography>
                            <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                Day
                            </Typography>
                        </center>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper sx={{ p: 4 }}>
                        <center>
                            <Typography variant="h3" sx={{ color: 'text.primary' }}>
                                6
                            </Typography>
                            <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                Hours
                            </Typography>
                        </center>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper sx={{ p: 4 }}>
                        <center>
                            <Typography variant="h3" sx={{ color: 'text.primary' }}>
                                24
                            </Typography>
                            <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                Min
                            </Typography>
                        </center>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper sx={{ p: 4 }}>
                        <center>
                            <Typography variant="h3" sx={{ color: 'text.primary' }}>
                                6
                            </Typography>
                            <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                Seconds
                            </Typography>
                        </center>
                    </Paper>
                </Grid>
            </Grid>

            <br />
            <br />
            <Typography sx={{ mt: 4 }} variant="h3" >
                Requirements
            </Typography>
            <Typography sx={{ mt: 2 }} variant="subtitle1">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </Typography>
        </Box>
    </>
  )
}

export default OrderView