import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_SERVICE } from 'src/config';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Dialog, DialogContentText, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


export default function ExchangeRates() {
    const [data, setData] = useState([]);
    const [editedData, setEditedData] = useState({});
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    // =====Edit
    const [editedDestination, setEditedDestination] = useState('');
    const [editedZone, setEditedZone] = useState('');
    const [editedType, setEditedType] = useState('');
    const [editedCountryCode, setEditedCountryCode] = useState('');
    const [editedContinent, setEditedContinent] = useState('');
    const [editedRates, setEditedRates] = useState('');

    // ========Pagination===============
    const pageSize = 22;
    const [pagination, setPagination] = useState({ count: pageSize, page: 1 })

    useEffect(() => {

        const fetchData = async () => {
            console.log("Inside effect func")
            try {
                const response = await axios.get(`${API_SERVICE}/allexchnageRate?page=${pagination.page}`);
                console.log('Axios Response:', response.data);

                const pageData = response.data.slice(0, pageSize);


                setData(pageData);
            } catch (error) {

                if (error.response) {

                } else if (error.request) {

                } else {

                }
            }
        };


        fetchData();
        // console.log("Pagination Values", pagination.count, pageSize, pagination.from, pagination.to);

    }, [pagination.page]);
    //------------------- Handling the pagination to move the products----------X
    const handlePagination = (e, page) => {
        setPagination({ ...pagination, page });
    }


    const handleEdit = (id) => {
        const editedItem = data.find((item) => item._id === id);
        setEditedData(editedItem);

        // Set initial values for form fields
        setEditedDestination(editedItem.destination);
        setEditedZone(editedItem.zone);
        setEditedType(editedItem.type);
        setEditedCountryCode(editedItem.countryCode);
        setEditedContinent(editedItem.continent);
        setEditedRates(editedItem.rates);

        setEditDialogOpen(true);
    };

    const handleEditSubmit = async () => {
        try {
            const updatedData = {
                destination: editedDestination,
                zone: editedZone,
                type: editedType,
                countryCode: editedCountryCode,
                continent: editedContinent,
                rates: editedRates,
            };

            const response = await axios.put(
                `${API_SERVICE}/updateexchangeRate/${editedData._id}`,
                updatedData
            );

            console.log('Data edited successfully:', response.data);
            setEditDialogOpen(false);

            // Refresh the data after editing
            fetchData();
        } catch (error) {
            console.error('Error editing data:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`${API_SERVICE}/deletexchangeRate/${id}`);
            console.log('Data deleted successfully:', response.data);
            // Refresh the data after deletion
            fetchData();
        } catch (error) {
            console.error('Error deleting data:', error);
        }
    };


    return (
        <div>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Destination Country</TableCell>
                            <TableCell align="center"> Zone</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">Country Code</TableCell>
                            <TableCell align="center">Continent</TableCell>
                            <TableCell align="center">Rates</TableCell>
                            <TableCell align="center">Edit/Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item) => {
                            return (
                                <TableRow key={item._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {item.destination}
                                    </TableCell>
                                    <TableCell align="center">{item.zone}</TableCell>
                                    <TableCell align="center">{item.type}</TableCell>

                                    <TableCell align="center">{item.countryCode}</TableCell>
                                    <TableCell align="center">{item.continent}</TableCell>
                                    <TableCell align="center">{item.rates}</TableCell>
                                    <TableCell align="center">
                                        <Button onClick={() => handleEdit(item._id)}>Edit</Button>
                                        <Button onClick={() => handleDelete(item._id)}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>

            </TableContainer>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} margin={"12px auto"}>

                <Pagination
                    count={pagination.count}
                    page={pagination.page}
                    onChange={handlePagination}
                />
            </Box>
            <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                <DialogTitle>Edit Data</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Destination Country"
                        value={editedDestination}
                        onChange={(e) => setEditedDestination(e.target.value)}
                        fullWidth
                        margin="normal"
                        size="large"
                    />
                    <TextField
                        label="Zone"
                        value={editedZone}
                        onChange={(e) => setEditedZone(e.target.value)}
                        fullWidth
                        margin="normal"
                        size="large"
                    />
                    <TextField
                        label="Type"
                        value={editedType}
                        onChange={(e) => setEditedType(e.target.value)}
                        fullWidth
                        margin="normal"
                        size="large"
                    />
                    <TextField
                        label="Country Code"
                        value={editedCountryCode}
                        onChange={(e) => setEditedCountryCode(e.target.value)}
                        fullWidth
                        margin="normal"
                        size="large"
                    />
                    <TextField
                        label="Continent"
                        value={editedContinent}
                        onChange={(e) => setEditedContinent(e.target.value)}
                        fullWidth
                        margin="normal"
                        size="large"
                    />
                    <TextField
                        label="Rates"
                        value={editedRates}
                        onChange={(e) => setEditedRates(e.target.value)}
                        fullWidth
                        margin="normal"
                        size="large"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleEditSubmit}>Save Changes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
