import SvgColor from '../../../components/svg-color';
import Chat from '@mui/icons-material/Chat';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import CustomerDataIcon from '@mui/icons-material/RecentActorsRounded'

// ----------------------------------------------------------------------
var navConfig = [];
const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
// const token=localStorage.getItem('token')

navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'Clients',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  {
    title: 'Category',
    path: '/dashboard/category',
    icon: icon('ic_disabled'),
  },
  {
    title: 'Customers Data',
    path: '/dashboard/customerdata',
    icon: <CustomerDataIcon />,
  },
  //  {
  //    title: 'Product',
  //    path: '/dashboard/products',
  //    icon: icon('ic_cart'),
  //  },
  {
    title: 'Admin Products',
    path: '/dashboard/adminProducts',
    icon: icon('ic_cart'),
  },
  {
    title: 'Exchange Rates',
    path: '/dashboard/exchangerates',
    icon: <CurrencyExchangeRoundedIcon />,

  },
  //  {
  //    title: 'Market Place',
  //    path: '/dashboard/marketplace',
  //    icon: icon('ic_cart'),
  //  },
  // {
  //   title: 'Orders',
  //   path: '/dashboard/ordersview',
  //   icon: icon('ic_blog'),
  // },
  {
    title: 'Admin Orders',
    path: '/dashboard/Adminorders',
    icon: icon('ic_blog'),
  },

  {
    title: 'Checked Out Orders',
    path: '/dashboard/checkoutOrders',
    icon: icon('ic_cart'),
  },
  {
    title: 'Messages',
    path: '/dashboard/messages',
    icon: <Chat />,
  },
  // {
  //   title: 'Customer Form',
  //   path: '/dashboard/CustomerForm',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Inventory',
  //   path: '/dashboard/inventory',
  //   icon: icon('ic_analytics'),
  // },
];
export default navConfig;
