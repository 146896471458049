import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack, FormGroup, FormControlLabel, Switch, Button } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import { useState } from 'react';
import { set, update } from 'lodash';
import { API_SERVICE } from '../../../config';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

ShopMarketplaceCard.propTypes = {
    product: PropTypes.object,
};

export default function ShopMarketplaceCard({ updateDialog, product }) {
    var { name, description, product_code, weight, length, diameter, material, color, price, stocks, category, available , img } = product;


    return (
        <Card>
            <CardMedia
                component="img"
                alt="green iguana"
                height="340"
                image={img}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {name}
                </Typography>
                <Typography gutterBottom variant="subtitle1" component="div">
                    {category}
                    <br />
                    {fCurrency(price)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {`${description.substring(0,50)}...`}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size='large' fullWidth variant='outlined' color='primary' onClick={()=>updateDialog(product)}>View More</Button>
            </CardActions>
        </Card>
    );
}
