// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------
var navConfig=[]
const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;


   navConfig = [
    {
      title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'Clients',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  {
    title: 'Category',
    path: '/dashboard/category',
    icon: icon('ic_disabled'),
  },
  {
    title: 'Product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },  
  {
    title: 'Account Settings',
    path: '/dashboard/accountSettings',
    icon: icon('ic_cart'),
  },
  // {
  //   title: 'Admin Products',
  //   path: '/dashboard/adminProducts',
  //   icon: icon('ic_cart'),
  // },
  {
    title: 'Market Place',
    path: '/dashboard/marketplace',
    icon: icon('ic_cart'),
  },
  {
    title: 'Customer Ordered',
    path: '/dashboard/CustomerOrdered',
    icon: icon('ic_cart'),
  },
  ];

export default navConfig;

  