import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_SERVICE } from 'src/config';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Dialog, DialogContentText, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { isExpired, decodeToken } from 'react-jwt';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

export default function Adminorder() {
  const [orders, setorders] = useState([]);
  const [show, setshow] = useState(false);
  //   const [more, setmore] = useState({CompanyWebsite:'',

  // category:'',
  // description:'',
  // diameter:'',
  // length:'',
  // material:'',
  // weight:'',
  // stocks:'',
  // img:''
  // });
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    const token1 = localStorage.getItem('token');
    var token = JSON.parse(token1);
    console.log(token);
    if (token) {
      const owner = decodeToken(token[0].Owner);
      const customer = decodeToken(token[0].Customer);
      if (!owner) {
        console.log('invalid token');
        localStorage.removeItem('token');
        Navigate('/Ownerlogin');
      } else {
        console.log('valid user');
      }
    }
  }, []);
  // const orders;
  const [event, setEvent] = useState({});
  const handleClickToOpen = (e) => {
    setOpen(true);
    setEvent(e);
  };

  const handleToClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    axios
      .get(`${API_SERVICE}/getAdminOrderedProduct`)
      .then((response) => {
        setorders(response.data);
        console.log('Orders:', orders);
        // orders=response.data
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const completeOrder = async (order) => {
    const productBody = { ...order };

    await addProduct(productBody);
    // console.log(customerEmail, productBody);
    await deleteOrderFromAdminandCustomer(order._id);
    handleToClose();
    window.location.reload(false);
  };

  const addProduct = async (productBody) => {
    const dataForm = {
      customerEmail: productBody?.customerEmail,
      name: productBody?.productName,
      product_code: productBody?.productCode,
      weight: productBody?.weight,
      length: productBody?.length,
      diameter: productBody?.diameter,
      material: productBody?.material,
      color: productBody?.color,
      price: productBody?.cost,
      stocks: productBody?.stocks,
      description: productBody?.description,
      available: true,
      category: productBody?.category,
      img: productBody?.img,
    };
    console.log(dataForm);
    try {
      const response = await fetch(`${API_SERVICE}/addProduct`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataForm),
      });
      if (response.status === 200) {
        // console.log('Ordered Product added to Customer product catalog!');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteOrderFromAdminandCustomer = async (id) => {
    try {
      const response = await fetch(`${API_SERVICE}/deleteOrder/${id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      // console.log('Delete:', response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Full Name</TableCell>
              <TableCell align="center"> Company Name</TableCell>
              <TableCell align="center">Billing Address</TableCell>
              <TableCell align="center">Product</TableCell>
              <TableCell align="center">Color</TableCell>
              <TableCell align="center">Qty.</TableCell>
              <TableCell align="center">Total Cost</TableCell>
              <TableCell align="center"> View More </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((item) => {
              return (
                <TableRow key={item._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {item.fname} {item.lname}
                  </TableCell>
                  <TableCell align="center">{item.CompanyName}</TableCell>
                  <TableCell align="center">{item.BillingAddress}</TableCell>

                  <TableCell align="center">{item.productName}</TableCell>
                  <TableCell align="center">{item.color}</TableCell>
                  <TableCell align="center">{item.qty}</TableCell>
                  <TableCell align="center">${(item.qty * item.cost).toFixed(2)}</TableCell>
                  <TableCell align="center">
                    <Button variant="outlined" sx={{ float: 'right' }} onClick={() => handleClickToOpen(item)}>
                      VIEW MORE
                    </Button>
                    <Dialog open={open} onClose={handleToClose}>
                      <DialogContent>
                        <img style={{ widht: '250px', height: '250px' }} src={event.img} />
                        {/* <Divider sx={{ my: 3 }} />
    <Stack  direction={{ xs: 'column', sm: 'row' }}
  spacing={{ xs: 1, sm: 2, md: 4 }}>

    CompanyName :      <Typography>
  {

    event.CompanyName
  }
</Typography>
</Stack>
<Divider sx={{ my: 3 }} /> */}
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          category : <Typography>{event.category}</Typography>
                        </Stack>

                        <Divider sx={{ my: 3 }} />

                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          description : <Typography>{event.description}</Typography>
                        </Stack>
                        <Divider sx={{ my: 3 }} />
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          length : <Typography>{event.length}</Typography>
                        </Stack>
                        <Divider sx={{ my: 3 }} />
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          material : <Typography>{event.material}</Typography>
                        </Stack>
                        <Divider sx={{ my: 3 }} />
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                          stocks : <Typography>{event.stocks}</Typography>
                        </Stack>

                        <Divider sx={{ my: 3 }} />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => completeOrder(item)} color="secondary" autoFocus>
                          Order Completed
                        </Button>
                        <Button onClick={handleToClose} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
