import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
import { API_SERVICE } from 'src/config';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useEffect, useState } from 'react';
import Axios from 'axios';
// import { jwt} from 'react-jwt'
import { isExpired, decodeToken } from 'react-jwt';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const Navigate = useNavigate();
  const [countOrders, setcountOrders] = useState();
  const [countCategory, setCountCategory] = useState(0);
  const [countProducts, setcountProducts] = useState();
  const [totalsales, setTotalSales] = useState(0);
  const [orderI, setOrderI] = useState(0);
  const theme = useTheme();
  useEffect(() => {
    const token1 = localStorage.getItem('token');
    var token = JSON.parse(token1);
    if (token) {
      const owner = decodeToken(token[0].Owner);
      const customer = decodeToken(token[0].Customer);
      if (!customer && !owner) {
        console.log('invalid token');
        localStorage.removeItem('token');
        Navigate('/Customerlogin');
      } else if(customer) {
        console.log('valid user');
        // countProductsByEmail for customers
        Axios.get(`${API_SERVICE}/countProductByEmail/${token[2]}`).then((response) => {
          console.log("get product response",response);
          setcountProducts(response.data.count);
        });
      
        Axios.get(`${API_SERVICE}/countOrderByEmail/${token[2]}`).then((response) => {
          // console.log(response);
          setcountOrders(response.data.count);
        });

        Axios.get(`${API_SERVICE}/getallcategories/fenfjsnfjewnjnelleslnlenfefneflk`).then((response) => {
          setCountCategory(response.data.length);
          // console.log(response.data.length)
        });
      }
      else if(owner){
        // countProductsByEmail for customers
        Axios.get(`${API_SERVICE}/countAdminProduct`).then((response) => {
          console.log("get product response",response);
          setcountProducts(response.data);
        });
      
        Axios.get(`${API_SERVICE}/countAdminOrder`).then((response) => {
          // console.log(response);
          setcountOrders(response.data);
        });

        Axios.get(`${API_SERVICE}/getallcategories/fenfjsnfjewnjnelleslnlenfefneflk`).then((response) => {
          setCountCategory(response.data.length);
          // console.log(response.data.length)
        });
        Axios
        .get(`${API_SERVICE}/getAdminOrderedProduct`)
        .then((response) => {
          console.log(response.data)
          console.log("ramesg")
   
          // console.log(orders)
          const totalvalue = calculateTotalValue(response.data);
          console.log(totalvalue);

        })
        .catch((err) => {
           console.log(err.message);
        });
        
      }

    }
  }, []);


  // useEffect(() => {
  //   const token1 = localStorage.getItem('token')
  //   var token = JSON.parse(token1);
     
  // }, []);

  const calculateTotalValue = (orderedItems) => {
  let total = 0;

  orderedItems.forEach(item => {
    total += item.qty* item.cost;
  });
    setTotalSales(total)
};
   console.log("ram");
   console.log(totalsales);

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={2} sm={6} md={3} alignItems="center">
            <AppWidgetSummary title="TOTAL SALES" total={String(totalsales)} />
          </Grid>
          <Grid item xs={2} sm={6} md={3} alignItems="center">
            {/* <AppWidgetSummary title="TOTAL ORDERS " total={countOrders} color="info"
             /> */}

            <AppWidgetSummary title="TOTAL ORDERS " total={String(countOrders)} color="info" />
          </Grid>

          <Grid item xs={2} sm={6} md={3} alignItems="center">
            <AppWidgetSummary title="TOTAL PRODUCTS " total={String(countProducts)} color="warning" />
          </Grid>
          <Grid item xs={2} sm={6} md={3} alignItems="center">
            <AppWidgetSummary title="TOTAL CATEGORIES " total={String(countCategory)} color="error" />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Sales Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                }
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid> */}
          {/* 
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}
          {/* 
          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
