import { Helmet } from 'react-helmet-async';
import React from 'react';
import { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';

import {
  Container,
  Paper,
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Pagination,
} from '@mui/material';
import { MarketplaceList } from 'src/sections/@dashboard/products';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Snackbar from '@mui/material/Snackbar';
import { fCurrency } from '../utils/formatNumber';
import CloseIcon from '@mui/icons-material/Close';
import { API_SERVICE } from 'src/config';
import { Form } from 'react-router-dom';
// import Checkform  from 'src/components/Checkform';
import { isExpired, decodeToken } from 'react-jwt';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

const OrderView = () => {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState([]);
  const [category, setCategory] = useState('All Category');
  const [categories, setCategories] = useState([]);
  const [productsData, setproductsData] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setloading] = useState(true);

  /// Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const PRODUCTS_PER_PAGE = 16;
  const totalPages = Math.ceil(productsData.length / PRODUCTS_PER_PAGE);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setloading(true);
    getAllProducts(page);
  };

  useEffect(() => {
    getAllCategories();
  }, []);
  const Navigate = useNavigate();
  useEffect(() => {
    const token1 = localStorage.getItem('token');
    var token = JSON.parse(token1);
    console.log(token);
    if (!token) {
      Navigate('/Customerlogin');
    } else if (token) {
      const owner = decodeToken(token[0].Owner);
      const customer = decodeToken(token[0].Customer);
      if (!customer) {
        console.log('invalid token');
        localStorage.removeItem('token');
        Navigate('/Customerlogin');
      } else {
        console.log(customer);
        console.log('valid user');
        // populateQuote()
      }
    }
  }, []);
  const getAllCategories = async () => {
    try {
      const response = await fetch(`${API_SERVICE}/getallcategories/fenfjsnfjewnjnelleslnlenfefneflk`);
      const data = await response.json();
      setCategories(data.filter(checkStatus));
      function checkStatus(c) {
        return c.status == true;
      }
      console.log(categories);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllProducts(currentPage);
  }, [currentPage]);

  const getAllProducts = async (page) => {
    try {
      const response = await fetch(`${API_SERVICE}/getallAdminProducts/dnjcndskjcnjnckncjsnajkcnjsansa`);
      const data = await response.json();
      const startIdx = (page - 1) * PRODUCTS_PER_PAGE;
      const endIdx = startIdx + PRODUCTS_PER_PAGE;
      const productsPage = data.slice(startIdx, endIdx);
      setproductsData(productsPage);

      setInterval(() => {
        setloading(false);
      }, 5000);

      setloading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const updateDialog = async (product) => {
    setState({
      name: product.name,
      category: product.category,
      product_code: product.product_code,
      weight: product.weight,
      length: product.length,
      diameter: product.diameter,
      material: product.material,
      color: product.color,
      price: product.price,
      stocks: product.stocks,
      description: product.description,
      available: product.available,
      img: product.img,
    });
    handleClickOpen();
  };
  const handleSearch = (e) => {
    if (category == 'All Category') {
      console.log(category);

      axios.get(`${API_SERVICE}/getSearchedAdminProduct/dnjcndskjcnjnckncjsnajkcnjsansa/${query}`).then((response) => {
        setproductsData(response.data);
        console.log(response);
      });
    } else {
      console.log(query);
      console.log(category);
      axios
        .get(`${API_SERVICE}/getSearchedAdminProduct/dnjcndskjcnjnckncjsnajkcnjsansa/${query}/${state}`)
        .then((response) => {
          setproductsData(response.data);
          console.log(response);
        });
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [age, setAge] = React.useState({ age: '' });
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const submitOrder = () => {
    console.log(state);
    handleClose();
    handleClickSnackbar();
  };
  /////////////////form
  const [postData, setPostData] = useState({ qty: '' });

  const navigate = useNavigate();

  const [isShown, setIsShown] = useState(false);
  const handleClick = (event) => {
    console.log(state);
    console.log(age);
    console.log(postData);
    const color = {
      color: age,
    };
    console.log(color);
    const data = [];
    data.push(state);
    data.push(postData);
    data.push(color);
    console.log(data);
    navigate('/dashboard/CustomerForm', { state: { data } });

    //     setIsShown(true);
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Order successfully submitted"
        action={action}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{state.name}</DialogTitle>
        <DialogContent>
          <center>
            {/* //https://res.cloudinary.com/daboha8rt/image/upload/v1670492461/ecomapp/Screenshot_2022-12-08_151048_lsdfiv.jpg */}
            <img style={{ widht: '250px', height: '250px' }} src={state.img} />
            <Typography gutterBottom variant="h5" component="div">
              {state.name} <br /> {state.category}
            </Typography>
            <Typography gutterBottom variant="subtitle1" component="div">
              {fCurrency(state.price)}
            </Typography>
          </center>

          <DialogContentText id="alert-dialog-description">{state.description}</DialogContentText>

          <Typography sx={{ mt: 2 }} gutterBottom variant="subtitle2" component="div">
            Informations
          </Typography>

          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={`Length : ${state.length} cm`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="#simple-list">
                <ListItemText primary={`Diameter : ${state.diameter} cm`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="#simple-list">
                <ListItemText primary={`Weight : ${state.weight} kg`} />
              </ListItemButton>
            </ListItem>
          </List>

          <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select the color</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                name="color"
                label="Select the color"
                onChange={handleChange}
                // onChange={(e) => setPostData({ ...postData, color: e.target.value })}
              >
                <MenuItem value="Red">Red</MenuItem>
                <MenuItem value="Blue">Blue</MenuItem>
                <MenuItem value="Violet">Violet</MenuItem>
                <MenuItem value="Purple">Purple</MenuItem>
                <MenuItem value="Green">Green</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Qty."
              name="qty"
              type="number"
              fullWidth
              placeholder="10"
              variant="outlined"
              onChange={(e) => setPostData({ ...postData, qty: e.target.value })}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={handleClose}>
            Close
          </Button>
          <Button size="large" onClick={handleClick} autoFocus>
            Submit order
          </Button>
        </DialogActions>
      </Dialog>

      <Helmet>
        <title> Dashboard: Marketplace | Minimal UI </title>
      </Helmet>

      <Box sx={{ mt: 5, mb: 5, mx: 2.5 }}>
        <Typography variant="h3" sx={{ color: 'text.primary', float: 'left' }}>
          Market Place
        </Typography>
        <br />
        <br />
        <br />
        <br />
        <center>
          <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500 }}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Products"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                // if (e.target.value !== "")
                //     getAllProducts(category,e.target.value);
              }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </Paper>

          <FormControl sx={{ mt: 4, minWidth: 300 }}>
            <InputLabel id="demo-simple-select-autowidth-label">Choose Category</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={category}
              label="Choose category"
              autoWidth
              onChange={(e) => {
                setCategory(e.target.value);
                // getAllProducts(e.target.value,query);
              }}
            >
              {<MenuItem value="All Category">All Category</MenuItem>}
              {categories.map((category) => {
                return (
                  <MenuItem key={category.id} value={category.categoryName}>
                    {category.categoryName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </center>

        {productsData.length === 0 ? (
          <center>
            <Typography variant="h6" sx={{ mt: 6 }}>
              No products found
            </Typography>
          </center>
        ) : (
          <>
            <MarketplaceList products={productsData} updateDialog={updateDialog} query={query} category={category} />
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="center" mb={1} mt={4}>
              <Pagination count={PRODUCTS_PER_PAGE} color="primary" page={currentPage} onChange={handlePageChange} />
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default OrderView;
