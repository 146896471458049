import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Container, Stack, Typography, Button, TextField, CircularProgress, ListItemText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/products';
import * as React from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import { API_SERVICE } from '../config';
import { isExpired, decodeToken } from 'react-jwt';
import FileInput from './firebase/FileInput';
// ----------------------------------------------------------------------

export default function ProductsPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(false);
  const [loading, setloading] = useState(true);
  const [productsData, setproductsData] = useState([]);
  const [category, setCategory] = useState('');
  const [productId, setproductId] = useState('');
  const [categories, setCategories] = useState([]);
  const [state, setState] = useState({
    name: '',
    product_code: '',
    weight: '',
    length: '',
    diameter: '',
    material: '',
    color: '',
    price: 0,
    stocks: 0,
    description: '',
    available: false,
  });
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setState({
      name: '',
      product_code: '',
      weight: '',
      length: '',
      diameter: '',
      material: '',
      color: '',
      price: 0,
      stocks: 0,
      description: '',
      available: false,
    });
  };

  const [open2, setOpen2] = useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const userEmail = JSON.parse(localStorage.getItem('token'))[2];
  // console.log(userEmail);

  useEffect(() => {
    if (state?.urls?.length === state?.img?.length) {
      setSubmitBtn(false);
    }
  }, [state]);

  useEffect(() => {
    const token1 = localStorage.getItem('token');
    var token = JSON.parse(token1);
    console.log(token);
    if (token) {
      const owner = decodeToken(token[0].Owner);
      const customer = decodeToken(token[0].Customer);
      if (!customer) {
        console.log('invalid token');
        localStorage.removeItem('token');
        Navigate('/Customerlogin');
      } else {
        console.log('valid user');
        // populateQuote()
      }
    }
  }, []);
  const addProduct = async () => {
    // console.log(category);
    setloading(true);
    const dataForm = {
      customerEmail: userEmail,
      name: state.name,
      product_code: state.product_code,
      weight: state.weight,
      length: state.length,
      diameter: state.diameter,
      material: state.material,
      color: state.color,
      price: state.price,
      stocks: state.stocks,
      description: state.description,
      available: state.available,
      // userId: user?.id,
      category: category,
      img: state.urls,
    };
    console.log(dataForm);
    try {
      const response = await fetch(`${API_SERVICE}/addProduct`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataForm),
      });
      if (response.status === 200) {
        handleClose();
        getAllProducts();
        setState({
          name: '',
          category: '',
          product_code: '',
          weight: '',
          length: '',
          diameter: '',
          material: '',
          color: '',
          price: 0,
          stocks: 0,
          description: '',
          available: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputState = (name, value) => {
    const values = Object.values(value);
    setState((prev) => ({ ...prev, [name]: values }));
  };

  //
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const getAllProducts = async () => {
    try {
      const response = await fetch(`${API_SERVICE}/getallproducts/${userEmail}`);
      const data = await response.json();
      console.log(data);
      setproductsData(data);
      setloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    try {
      const response = await fetch(`${API_SERVICE}/getallcategories/${userEmail}`);
      const data = await response.json();
      setCategories(data.filter(checkStatus));
      function checkStatus(c) {
        return c.status == true;
      }
      console.log(categories);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteProduct = async (id) => {
    setloading(true);
    try {
      // console.log(id)
      const response = await fetch(`${API_SERVICE}/deleteproduct/${id}/${userEmail}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      getAllProducts();
    } catch (err) {
      console.log(err);
    }
  };

  const updateProduct = async (product) => {
    setState({
      name: product.name,
      category: product.category,
      product_code: product.product_code,
      weight: product.weight,
      length: product.length,
      diameter: product.diameter,
      material: product.material,
      color: product.color,
      price: product.price,
      stocks: product.stocks,
      description: product.description,
      available: product.available,
    });
    setproductId(product?._id);
    handleClickOpen2();
  };

  const updateProduct2 = async () => {
    setloading(true);
    try {
      const response = await fetch(`${API_SERVICE}/updateproduct/${productId}/${userEmail}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...state }),
      });
      getAllProducts();
      handleClose2();
      setState({
        name: '',
        category: '',
        product_code: '',
        weight: '',
        length: '',
        diameter: '',
        material: '',
        color: '',
        price: 0,
        stocks: 0,
        description: '',
        available: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const copyProduct = async (product) => {
    setState({
      name: product.name,
      category: product.category,
      product_code: product.product_code,
      weight: product.weight,
      length: product.length,
      diameter: product.diameter,
      material: product.material,
      color: product.color,
      price: product.price,
      stocks: product.stocks,
      description: product.description,
      available: product.available,
    });
    setproductId(product?._id);
    handleClickOpen();
  };

  return (
    <>
      <Dialog
        open={open2}
        maxWidth="md"
        fullWidth
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Product</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="name"
            onChange={handleChange}
            value={state.name}
            label="Name"
          />
          {/* <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant='outlined'
            name="category"
            onChange={handleChange}
            value={state.category}
            label='Category'
          /> */}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              label="Category"
              onChange={(e) => setCategory(e.target.value)}
            >
              {categories.map((category) => {
                return (
                  <MenuItem key={category.id} value={category.categoryName}>
                    {/* <ListItemText primary={category.categoryName}/> */}
                    {/* `${category.categoryName}` */}
                    {category.categoryName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="product_code"
            onChange={handleChange}
            value={state.product_code}
            label="Product Code"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="weight"
            onChange={handleChange}
            value={state.weight}
            label="Weight"
          />
          <Box sx={{ '& > :not(style)': { my: 2, mx: 1, width: '32ch', width: 401 } }} label="Dimensions">
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              name="length"
              onChange={handleChange}
              value={state.length}
              label="Length(in cm)"
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              name="diameter"
              onChange={handleChange}
              value={state.diameter}
              label="Diameter(in cm)"
            />
          </Box>

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="material"
            onChange={handleChange}
            value={state.material}
            label="Material"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="color"
            onChange={handleChange}
            value={state.color}
            label="Color"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            type="number"
            name="price"
            onChange={handleChange}
            value={state.price}
            label="Price( in $ )"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            type="number"
            variant="outlined"
            name="stocks"
            onChange={handleChange}
            value={state.stocks}
            label="Number Stocks"
          />
          <TextField
            multiline
            rows={4}
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="description"
            onChange={handleChange}
            value={state.description}
            label="Description"
          />
          {/* <FormControlLabel
            control={<Switch color="primary" />}
            checked={state.available}
            label="Available"
            labelPlacement="start"
            name="available"
            onChange={handleChange}
          /> */}
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="available"
            value={state.available}
            onChange={handleChange}
          >
            <FormControlLabel value={true} control={<Radio />} label="Available" />
            <FormControlLabel value={false} control={<Radio />} label="Not Available" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>Close</Button>
          <Button
            onClick={() => {
              updateProduct2();
            }}
            autoFocus
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Product</DialogTitle>
        <DialogContent>
          <TextField
            required
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="name"
            onChange={handleChange}
            value={state.name}
            label="Name"
          />

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              label="Category"
              onChange={(e) => setCategory(e.target.value)}
            >
              {categories.map((category) => {
                return (
                  <MenuItem key={category.id} value={category.categoryName}>
                    {category.categoryName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            required
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="product_code"
            onChange={handleChange}
            value={state.product_code}
            label="Product Code"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="weight"
            onChange={handleChange}
            value={state.weight}
            label="Weight"
          />
          <Box sx={{ '& > :not(style)': { my: 2, mx: 1, width: '32ch', width: 401 } }} label="Dimensions">
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              name="length"
              onChange={handleChange}
              value={state.length}
              label="Length(in cm)"
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              name="diameter"
              onChange={handleChange}
              value={state.diameter}
              label="Diameter(in cm)"
            />
          </Box>

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="material"
            onChange={handleChange}
            value={state.material}
            label="Material"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="color"
            onChange={handleChange}
            value={state.color}
            label="Color"
          />
          <TextField
            required
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            type="number"
            name="price"
            onChange={handleChange}
            value={state.price}
            label="Price( in $ )"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            type="number"
            variant="outlined"
            name="stocks"
            onChange={handleChange}
            value={state.stocks}
            label="Number Stocks"
          />
          <TextField
            multiline
            rows={4}
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            name="description"
            onChange={handleChange}
            value={state.description}
            label="Description"
          />
          {/* <FormControlLabel
            control={<Switch color="primary" />}
            checked={state.available}
            label="Available"
            labelPlacement="start"
            name="available"
            onChange={handleChange}
          /> */}
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="available"
            value={state.available}
            onChange={handleChange}
          >
            <FileInput
              name="img"
              label="Choose Image"
              handleInputState={handleInputState}
              type="image"
              files={state.img}
              state={setState}
              value=""
              btnState={setSubmitBtn}
            />
            <FormControlLabel value={true} control={<Radio />} label="Available" />
            <FormControlLabel value={false} control={<Radio />} label="Not Available" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            disabled={submitBtn}
            onClick={() => {
              addProduct();
            }}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Helmet>
        <title> Dashboard: Products | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
          <Button onClick={handleClickOpen} variant="contained">
            New Product
          </Button>
        </Stack>

        {loading ? (
          <center>
            <CircularProgress sx={{ mt: 20 }} />
          </center>
        ) : (
          <>
            {productsData?.length === 0 ? (
              <center>
                <Typography variant="h6" sx={{ mt: 6 }}>
                  No products found
                </Typography>
              </center>
            ) : (
              <>
                <Stack
                  direction="row"
                  flexWrap="wrap-reverse"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ mb: 5 }}
                >
                  <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                    <ProductFilterSidebar
                      openFilter={openFilter}
                      onOpenFilter={handleOpenFilter}
                      onCloseFilter={handleCloseFilter}
                    />
                    <ProductSort />
                  </Stack>
                </Stack>

                <ProductList
                  copyProduct={copyProduct}
                  updateProduct={updateProduct}
                  updateProduct2={updateProduct2}
                  deleteProduct={deleteProduct}
                  products={productsData}
                  setloading={setloading}
                  getProduct={getAllProducts}
                />
              </>
            )}
          </>
        )}

        <ProductCartWidget />
      </Container>
    </>
  );
}
