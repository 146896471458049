import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopMarketplaceCard from './MarketplaceCard';

// ----------------------------------------------------------------------

MarketplaceList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function MarketplaceList({ products,updateDialog,...other }) {
  return (
    <Grid sx={{ mt: 4 }} container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product._id} item xs={6} sm={3}>
          <ShopMarketplaceCard  product={product}  updateDialog={updateDialog}/>
        </Grid>
      ))}
    </Grid>
  );
}
