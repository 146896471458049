import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopAdminProductCard from './AdminProductCard';

// ----------------------------------------------------------------------

AdminProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function AdminProductList({ copyProduct, updateProduct,updateProduct2, deleteProduct, products,setloading,getProduct,...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product._id} item xs={12} sm={6} md={3}>
          <ShopAdminProductCard copyProduct={copyProduct} updateProduct={updateProduct} updateProduct2={updateProduct2} deleteProduct={deleteProduct} product={product} setloading={setloading} getProduct={getProduct}/>
        </Grid>
      ))}
    </Grid>
  );
}
